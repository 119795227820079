<template>
  <div><treatment-type-list /></div>
</template>

<script>
import TreatmentTypeList from "./components/TreatmentType/TreatmentTypeList.vue";
export default {
  components: { TreatmentTypeList },
};
</script>

<style></style>
